import axios from "axios";

import authHeader from "./auth-header";

const API_URL = "https://haulier-api.2assistu.ch/api/hauliers/";
//const API_URL = "http://localhost:8080/api/hauliers/";

class HauliersService {
  // Retrieve all published hauliers
  getAllPublishedHauliers() {
    return axios.get(API_URL + "published");
  }

  // Retrieve all hauliers
  getAllHauliers() {
    return axios.get(API_URL, { headers: authHeader() });
  }

  // Create new haulier
  async createHaulier(data) {
    const response = await axios.post(API_URL, data, { headers: authHeader() });
    return response.data;
  }

  // Edit haulier by id
  async editHaulier(id, data) {
    const response = await axios.put(API_URL + id, data, {
      headers: authHeader(),
    });
    return response.data;
  }

  // Delete a haulier by id
  deleteHaulier(id) {
    return axios.delete(API_URL + id, { headers: authHeader() });
  }
}

export default new HauliersService();
