import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import AuthService from "../services/auth.service";
import translations from "../config/translations";
import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import { ThreeDots } from "react-loader-spinner";

export default function LoginForm({ language, setisAdmin }) {
  let navigate = useNavigate();
  const { register, handleSubmit, reset } = useForm();
  const { promiseInProgress } = usePromiseTracker();

  const onSubmit = (data) => {
    trackPromise(AuthService.login(data.username, data.password)).then(
      () => {
        setisAdmin(true);
        reset();
        navigate("/adminTable");
      },
      (error) => {
        reset();
      }
    );
  };

  return (
    <div className="login-box">
      <h2>{translations[language].button.login}</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="user-box">
          <input
            type="text"
            name=""
            required={true}
            {...register("username", { required: true })}
          />
          <label>{translations[language].loginForm.username}</label>
        </div>
        <div className="user-box">
          <input
            type="password"
            name=""
            required={true}
            {...register("password", { required: true })}
          />
          <label>{translations[language].loginForm.password}</label>
        </div>
        {promiseInProgress ? (
          <div className="login-loading-spinner">
            <ThreeDots color="#000" height={70} width={70} />
          </div>
        ) : (
          <></>
        )}
        <button className="submit-button" type="submit">
          {translations[language].button.login}
        </button>
      </form>
    </div>
  );
}
