import React from "react";
import packageJson from "../../package.json";

function Footer() {
  return (
    <div className="footer-container">
      <p className="footer-text">&copy;2assistU AG - v</p>
      <p className="footer-text">{packageJson.version}</p>
    </div>
  );
}

export default Footer;
