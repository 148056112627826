import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import translations from "../config/translations";
import hauliersService from "../services/hauliers.service";

export default function CreateForm({ language }) {
  let navigate = useNavigate();
  const { register, handleSubmit, reset } = useForm();
  const onSubmit = (data) => {
    // Add time and timezone to expiry date before call to API
    data.expiry_date = data.expiry_date + "T00:00:00.000Z";
    hauliersService.createHaulier(data).then(
      (response) => {
        reset();
        navigate("/adminTable");
      },
      (error) => {}
    );
  };

  return (
    <div className="create-box">
      <h2>{translations[language].createForm.formTitle}</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-columns">
          <div className="form-column">
            <div className="user-box">
              <input
                type="text"
                name=""
                required={true}
                {...register("register_number", { required: true })}
              />
              <label>{translations[language].column.registerNr}</label>
            </div>
            <div className="user-box">
              <input
                type="text"
                name=""
                required={true}
                {...register("name", { required: true })}
              />
              <label>{translations[language].column.name}</label>
            </div>
            <div className="user-box">
              <input
                type="text"
                name=""
                required={true}
                {...register("address", { required: true })}
              />
              <label>{translations[language].column.address}</label>
            </div>
            <div className="user-box">
              <input
                type="text"
                name=""
                required={true}
                {...register("zip", { required: true })}
              />
              <label>{translations[language].column.postalCode}</label>
            </div>
            <div className="user-box">
              <input
                type="text"
                name=""
                required={true}
                {...register("city", { required: true })}
              />
              <label>{translations[language].column.city}</label>
            </div>
          </div>
          <div className="form-column">
            <div className="user-box-date">
              <input
                type="date"
                name=""
                required={true}
                {...register("expiry_date", { required: true })}
              />
              <label>{translations[language].column.expiringDate}</label>
            </div>
            <div className="user-box">
              <input type="text" name="" {...register("responsable_name")} />
              <label>{translations[language].column.responsableName}</label>
            </div>
            <div className="user-box">
              <input type="text" name="" {...register("responsable_phone")} />
              <label>{translations[language].column.responsablePhone}</label>
            </div>
            <div className="user-box">
              <input type="text" name="" {...register("responsable_email")} />
              <label>{translations[language].column.responsableEmail}</label>
            </div>
            <div className="user-box-checkbox">
              <input type="checkbox" name="" {...register("active")} />
              <label>{translations[language].column.active}</label>
            </div>
            <div className="user-box-checkbox">
              <input type="checkbox" name="" {...register("published")} />
              <label>{translations[language].column.published}</label>
            </div>
          </div>
        </div>
        <div className="user-box-textarea">
          <textarea rows="5" {...register("internal_remarks")}></textarea>
          <label>{translations[language].column.notes}</label>
        </div>
        <button className="submit-button" type="submit">
          {translations[language].createForm.formTitle}
        </button>
      </form>
    </div>
  );
}
