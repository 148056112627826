import React, { useState, useEffect, useMemo } from "react";
import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import Moment from "moment";

import translations from "../config/translations";
import HauliersService from "../services/hauliers.service";
import Table from "./Table";
import LoadingSpinner from "./LoadingSpinner";

export default function PublicTable({ language }) {
  const { promiseInProgress } = usePromiseTracker();
  const [publishedHauliers, setPublishedHauliers] = useState([]);

  useEffect(() => {
    (async () => {
      const response = await trackPromise(
        HauliersService.getAllPublishedHauliers()
      );
      setPublishedHauliers(response.data);
    })();
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: translations[language].column.registerNr,
        accessor: "register_number", // accessor is the "key" in the data,
      },
      {
        Header: translations[language].column.name,
        accessor: "name",
      },
      {
        Header: translations[language].column.address,
        accessor: "address",
      },
      {
        Header: translations[language].column.postalCode,
        accessor: "zip",
        collapse: true,
      },
      {
        Header: translations[language].column.city,
        accessor: "city",
      },
      {
        Header: translations[language].column.expiringDate,
        accessor: "expiry_date",
        Cell: ({ cell: { value } }) => {
          const date = new Date(value);
          const dateString = Moment(date).format("DD.MM.YYYY");
          return <>{dateString}</>;
        },
      },
      {
        Header: translations[language].column.active,
        accessor: "active",
        Cell: ({ cell: { value } }) => {
          return (
            <span className={value === true ? "true-badge" : "false-badge"}>
              {value === true
                ? translations[language].table.yes
                : translations[language].table.no}
            </span>
          );
        },
      },
    ],
    [language]
  );

  return (
    <div className="table-container">
      {promiseInProgress ? (
        <LoadingSpinner />
      ) : (
        <Table columns={columns} data={publishedHauliers} />
      )}
    </div>
  );
}
