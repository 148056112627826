import { useState } from "react";
import {
  HashRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import "./styles/app.scss";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import Header from "./components/Header";
import PublicTable from "./components/PublicTable";
import AdminTable from "./components/AdminTable";
import LoginForm from "./components/LoginForm";
import CreateForm from "./components/CreateForm";
import Footer from "./components/Footer";

function App() {
  const [language, setLanguage] = useState("de");
  const [isAdmin, setisAdmin] = useState(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      const roles = user.roles;
      return roles.includes("ROLE_SUPERADMIN") || roles.includes("ROLE_ADMIN");
    } else {
      return false;
    }
  });

  return (
    <Router>
      <Header
        language={language}
        setLanguage={setLanguage}
        setisAdmin={setisAdmin}
        isAdmin={isAdmin}
      />
      <div className="container">
        <Routes>
          <Route path="/" exact element={<PublicTable language={language} />} />
          <Route
            path="/login"
            exact
            element={<LoginForm language={language} setisAdmin={setisAdmin} />}
          />
          {isAdmin ? (
            <Route
              path="/adminTable"
              exact
              element={<AdminTable language={language} />}
            />
          ) : (
            <Route
              path="/adminTable"
              element={<Navigate to="/login" replace />}
            />
          )}
          {isAdmin ? (
            <Route
              path="/create"
              exact
              element={<CreateForm language={language} />}
            />
          ) : (
            <Route path="/create" element={<Navigate to="/login" replace />} />
          )}
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </div>
      <Footer />
    </Router>
  );
}

export default App;
