import React, { useState, useEffect, useMemo } from "react";
import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import Moment from "moment";

import translations from "../config/translations";
import HauliersService from "../services/hauliers.service";
import Table from "./Table";
import LoadingSpinner from "./LoadingSpinner";
import Editor from "./Editor";

export default function AdminTable({ language }) {
  const [refresh, setRefresh] = useState(1);
  const { promiseInProgress } = usePromiseTracker();
  const [allHauliers, setAllHauliers] = useState([]);
  const [selectedRow, setSelectedRow] = useState({});

  useEffect(() => {
    (async () => {
      const response = await trackPromise(HauliersService.getAllHauliers());
      setAllHauliers(response.data);
    })();
  }, [refresh]);

  const columns = useMemo(
    () => [
      {
        Header: translations[language].column.registerNr,
        accessor: "register_number", // accessor is the "key" in the data,
      },
      {
        Header: translations[language].column.name,
        accessor: "name",
      },
      {
        Header: translations[language].column.address,
        accessor: "address",
      },
      {
        Header: translations[language].column.postalCode,
        accessor: "zip",
      },
      {
        Header: translations[language].column.city,
        accessor: "city",
      },
      {
        Header: translations[language].column.expiringDate,
        accessor: "expiry_date",
        Cell: ({ cell: { value } }) => {
          const date = new Date(value);
          const dateString = Moment(date).format("DD.MM.YYYY");
          return <>{dateString}</>;
        },
      },
      {
        Header: translations[language].column.active,
        accessor: "active",
        Cell: ({ cell: { value } }) => {
          return (
            <span className={value === true ? "true-badge" : "false-badge"}>
              {value === true
                ? translations[language].table.yes
                : translations[language].table.no}
            </span>
          );
        },
      },
      {
        Header: translations[language].column.published,
        accessor: "published",
        Cell: ({ cell: { value } }) => {
          return (
            <span className={value === true ? "true-badge" : "false-badge"}>
              {value === true
                ? translations[language].table.yes
                : translations[language].table.no}
            </span>
          );
        },
      },
      {
        Header: translations[language].column.notes,
        accessor: "internal_remarks",
      },
    ],
    [language]
  );

  return (
    <div className="table-container">
      <div className="container-admin">
        {promiseInProgress ? (
          <LoadingSpinner />
        ) : (
          <Table
            className="container-admin-table"
            columns={columns}
            data={allHauliers}
            selectedRow={selectedRow}
            setSelectedRow={setSelectedRow}
          />
        )}
        <Editor
          className="container-admin-editor"
          language={language}
          setSelectedRow={setSelectedRow}
          selectedRow={selectedRow}
          refresh={refresh}
          setRefresh={setRefresh}
        />
      </div>
    </div>
  );
}
