const translations = {
  de: {
    app: {
      title: "Liste der zugelassenen Transporteure (CH)",
    },
    column: {
      registerNr: "Registriernummer",
      name: "Name",
      address: "Anschrift",
      postalCode: "Postleitzahl",
      city: "Ort",
      expiringDate: "Ablaufdatum",
      active: "Aktiv",
      published: "Publiziert",
      notes: "Notizen",
      responsableName: "Verantwortlicher",
      responsablePhone: "Telefon Verantwortlicher",
      responsableEmail: "E-Mail Verantwortlicher",
    },
    button: {
      login: "Login",
      logout: "Logout",
      edit: "Bearbeiten",
      save: "Speichern",
      cancel: "Abbrechen",
    },
    table: {
      yes: "Ja",
      no: "Nein",
    },
    loginForm: {
      username: "Benutzername",
      password: "Passwort",
    },
    createForm: {
      formTitle: "Haulier erstellen",
    },
    editForm: {
      editButton: "Änderungen speichern",
      deleteButton: "Löschen",
      deleteConfirmation: "Möchten sie diesen Eintrag löschen?",
    },
  },
  fr: {
    app: {
      title: "Liste des transporteurs agréés (CH)",
    },
    column: {
      registerNr: "Numéro d’inscription",
      name: "Nom",
      address: "Adresse",
      postalCode: "Code Postal",
      city: "Ville",
      expiringDate: "Date d’expiration",
      active: "Actif",
      published: "Publié",
      notes: "Notes",
      responsableName: "Responsable",
      responsablePhone: "Téléphone du responsable",
      responsableEmail: "E-mail du responsable",
    },
    button: {
      login: "Login",
      logout: "Logout",
      edit: "Modifier",
      save: "Sauver",
      cancel: "Annuler",
    },
    table: {
      yes: "Oui",
      no: "Non",
    },
    loginForm: {
      username: "Nom d'utilisateur",
      password: "Mot de passe",
    },
    createForm: {
      formTitle: "Create Haulier",
      deleteButton: "Delete",
    },
    editForm: {
      editButton: "Save changes",
      deleteButton: "Delete",
      deleteConfirmation: "Souhaitez-vous supprimer cette entrée?",
    },
  },
  it: {
    app: {
      title: "Lista dei trasportatori autorizzati (CH)",
    },
    column: {
      registerNr: "Numero di registrazione",
      name: "Nome",
      address: "Indirizzo",
      postalCode: "Codice postale",
      city: "Città",
      expiringDate: "Data di scadenza",
      active: "Attivo",
      published: "Pubblicato",
      notes: "Note",
      responsableName: "Responsabile",
      responsablePhone: "Telefono responsabile",
      responsableEmail: "E-mail responsabile",
    },
    button: {
      login: "Login",
      logout: "Logout",
      edit: "Modifica",
      save: "Salva",
      cancel: "Cancellare",
    },
    table: {
      yes: "Sì",
      no: "No",
    },
    loginForm: {
      username: "Nome utente",
      password: "Password",
    },
    createForm: {
      formTitle: "Create Haulier",
    },
    editForm: {
      editButton: "Save changes",
      deleteButton: "Delete",
      deleteConfirmation: "Volete cancellare questa voce?",
    },
  },
};

export default translations;
