import React, { useEffect } from "react";
import { useForm } from "react-hook-form";

import translations from "../config/translations";
import hauliersService from "../services/hauliers.service";

export default function Editor({
  language,
  setSelectedRow,
  selectedRow,
  refresh,
  setRefresh,
}) {
  const { register, handleSubmit, reset, setValue } = useForm();
  const onEdit = (data) => {
    if (selectedRow.id !== undefined) {
      // Add time and timezone to expiry date before call to API
      data.expiry_date = data.expiry_date + "T00:00:00.000Z";
      hauliersService.editHaulier(selectedRow.id, data).then(
        (response) => {
          setSelectedRow({});
          setRefresh(refresh + 1);
          reset();
        },
        (error) => {}
      );
    }
  };

  const deleteAction = () => {
    if (selectedRow.id !== undefined) {
      hauliersService.deleteHaulier(selectedRow.id).then(
        (response) => {
          setSelectedRow({});
          setRefresh(refresh + 1);
          reset();
        },
        (error) => {}
      );
    }
  };

  useEffect(() => {
    setValue("register_number", selectedRow.register_number);
    setValue("name", selectedRow.name);
    setValue("address", selectedRow.address);
    setValue("zip", selectedRow.zip);
    setValue("city", selectedRow.city);
    setValue(
      "expiry_date",
      selectedRow.expiry_date ? selectedRow.expiry_date.substring(0, 10) : ""
    );
    setValue("active", selectedRow.active);
    setValue("published", selectedRow.published);
    setValue("internal_remarks", selectedRow.internal_remarks);
    setValue("responsable_name", selectedRow.responsable_name);
    setValue("responsable_phone", selectedRow.responsable_phone);
    setValue("responsable_email", selectedRow.responsable_email);
  });

  return (
    <div className="editor-form">
      <form onSubmit={handleSubmit(onEdit)}>
        <div className="form-columns">
          <div className="form-column">
            <div className="user-box">
              <input
                type="text"
                name=""
                required={true}
                {...register("register_number", { required: true })}
              />
              <label>{translations[language].column.registerNr}</label>
            </div>
            <div className="user-box">
              <input
                type="text"
                name=""
                required={true}
                {...register("name", { required: true })}
              />
              <label>{translations[language].column.name}</label>
            </div>
            <div className="user-box">
              <input
                type="text"
                name=""
                required={true}
                {...register("address", { required: true })}
              />
              <label>{translations[language].column.address}</label>
            </div>
            <div className="user-box">
              <input
                type="text"
                name=""
                required={true}
                {...register("zip", { required: true })}
              />
              <label>{translations[language].column.postalCode}</label>
            </div>
            <div className="user-box">
              <input
                type="text"
                name=""
                required={true}
                {...register("city", { required: true })}
              />
              <label>{translations[language].column.city}</label>
            </div>
          </div>
          <div className="form-column">
            <div className="user-box-date">
              <input
                type="date"
                name=""
                required={true}
                {...register("expiry_date", { required: true })}
              />
              <label>{translations[language].column.expiringDate}</label>
            </div>
            <div className="user-box">
              <input type="text" name="" {...register("responsable_name")} />
              <label>{translations[language].column.responsableName}</label>
            </div>
            <div className="user-box">
              <input type="text" name="" {...register("responsable_phone")} />
              <label>{translations[language].column.responsablePhone}</label>
            </div>
            <div className="user-box">
              <input type="text" name="" {...register("responsable_email")} />
              <label>{translations[language].column.responsableEmail}</label>
            </div>
            <div className="user-box-checkbox">
              <input type="checkbox" name="" {...register("active")} />
              <label>{translations[language].column.active}</label>
            </div>
            <div className="user-box-checkbox">
              <input type="checkbox" name="" {...register("published")} />
              <label>{translations[language].column.published}</label>
            </div>
          </div>
        </div>
        <div className="user-box-textarea">
          <textarea rows="5" {...register("internal_remarks")}></textarea>
          <label>{translations[language].column.notes}</label>
        </div>
        <button className="edit-button" type="submit">
          {translations[language].editForm.editButton}
        </button>
      </form>
      <button
        className="delete-button"
        onClick={() => {
          window.confirm(translations[language].editForm.deleteConfirmation) &&
            deleteAction();
        }}
      >
        {translations[language].editForm.deleteButton}
      </button>
    </div>
  );
}
