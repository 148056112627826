import React from "react";
import { useNavigate, Link } from "react-router-dom";

import translations from "../config/translations";
import Logo from "./Logo";
import AuthService from "../services/auth.service";

function Header({ language, setLanguage, setisAdmin, isAdmin }) {
  let navigate = useNavigate();

  const loginLogoutAction = () => {
    if (isAdmin) {
      AuthService.logout();
      setisAdmin(false);
      navigate("/");
    } else {
      navigate("/login");
    }
  };

  return (
    <div className="header">
      <div className="header-container1">
        <Logo />
      </div>
      <div className="header-container">
        <div className="logo-container">
          <p className="logo-title">{translations[language].app.title}</p>
        </div>
        <div className="header-buttons">
          {isAdmin ? (
            <>
              <button className="normal-button">
                <Link className="button-link" to="/create">
                  Haulier erstellen
                </Link>
              </button>
              <button className="normal-button">
                <Link className="button-link" to="/adminTable">
                  Admin Table
                </Link>
              </button>
              <button className="normal-button">
                <Link className="button-link" to="/">
                  Public Table
                </Link>
              </button>
              <button className="normal-button" onClick={loginLogoutAction}>
                {isAdmin
                  ? translations[language].button.logout
                  : translations[language].button.login}
              </button>
              <span className="button-span"></span>
            </>
          ) : (
            <></>
          )}
          <select
            className="dropdown-language"
            value={language}
            onChange={(e) => setLanguage(e.target.value)}
          >
            <option value="de">Deutsch</option>
            <option value="fr">Français</option>
            <option value="it">Italiano</option>
          </select>
        </div>
      </div>
    </div>
  );
}

export default Header;
