import React from "react";
import {
  useTable,
  useSortBy,
  useRowSelect,
  useGlobalFilter,
} from "react-table";
//import { GlobalFilter } from "./GlobalFilter";

export default function Table({ columns, data, selectedRow, setSelectedRow }) {
  // Use the useTable Hook to send the columns and data to build the table
  const {
    getTableProps, // table props from react-table
    getTableBodyProps, // table body props from react-table
    headerGroups, // headerGroups, if your table has groupings
    rows, // rows for the table based on the data passed
    prepareRow, // Prepare the row (this function needs to be called for each row before getting the row props)
    //state,
    //setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      setSelectedRow,
      initialState: {
        sortBy: [
          {
            id: "register_number",
            desc: false,
          },
        ],
      },
    },
    useGlobalFilter,
    useSortBy,
    useRowSelect
  );

  //const { globalFilter } = state

  return (
    <>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? " ⇈"
                        : " ⇊"
                      : " ⮃"}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                onClick={() =>
                  selectedRow !== undefined ? setSelectedRow(row.original) : {}
                }
                style={
                  selectedRow !== undefined
                    ? row.original.id === selectedRow.id
                      ? { backgroundColor: "rgba(20,33,61,0.5)" }
                      : {}
                    : {}
                }
              >
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {/*<GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />*/}
    </>
  );
}
