import React from "react";
import { useNavigate } from "react-router-dom";

import logo_bazl from "../assets/logo_bazl.png";
import logo_2assistu from "../assets/logo_2assistu.png";

export default function Logo() {
  let navigate = useNavigate();
  return (
    <div className="logo-container">
      <img
        className="logo"
        src={logo_bazl}
        alt="logo"
        onClick={() => navigate("/")}
      />
      <img
        className="logo"
        src={logo_2assistu}
        alt="logo"
        onClick={() => navigate("/")}
      />
    </div>
  );
}
