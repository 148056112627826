import axios from "axios";

const API_URL = "https://haulier-api.2assistu.ch/api/auth/";

class AuthService {
  // login user & save JWT to local storage
  async login(username, password) {
    const response = await axios.post(API_URL + "signin", {
      username,
      password,
    });
    if (response.data.accessToken) {
      localStorage.setItem("user", JSON.stringify(response.data));
    }
    return response.data;
  }

  // remove JWT from Local Storage
  logout() {
    localStorage.removeItem("user");
  }

  // register new user
  register(username, email, password) {
    return axios.post(API_URL + "signup", {
      username,
      email,
      password,
    });
  }

  // get stored user information (including JWT)
  getCurrentUser() {
    return JSON.parse(localStorage.getItem("user"));
  }
}

export default new AuthService();
